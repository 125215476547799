<template>
    <div>
        <Header></Header>
        <div class="container-fluid">
            <div class="row">
                <SideMenu></SideMenu>
                <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <router-view></router-view>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import Header from '../components/Header.vue'
import SideMenu from '../components/SideMenu.vue'
export default {
    setup() {
        const store = useStore()

        // store.commit('checkRoutePermissions')

        return {
            store,
        }
    },
    components: {
        Header,
        SideMenu,
    },

}
</script>

<style lang="scss" scoped>

</style>