
import axios from 'axios';

export default ({ to, from, next }) => {
    // Your custom if statement
    if (!localStorage.getItem('eqt_jwt')) {
        window.location.href = "/login";
        return false
    }else{
        if (localStorage.getItem('eqt_adminmenu')) {

            window.location.href = "/admin";
            return false
        }else{
            window.location.href = "/e-quotation";
            return false
        }
    }



    next()
}