<template>
  <div class="form-left h-100 py-5 px-5">
    <div class="row py-5">
      <div class="col-1">
        <img src="../../assets/logo_toa_.png" alt="" width="50" />
      </div>
      <div class="col text-center">
        <h1>TOA E-QUOTATION</h1>
      </div>
    </div>
    <form action="" class="row g-4 mt-3 py-5">
      <div class="col-12">
        <div class="input-group">
          <div class="input-group-text">
            <i class="bi bi-person-fill"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                /></svg
            ></i>
          </div>
          <input
            v-on:keyup.enter="login"
            :class="employeeIdClass"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            type="text"
            minlength="8"
            maxlength="8"
            v-model="form.employeeId"
            autocomplete="username"
            class="form-control"
            placeholder="รหัสพนักงาน"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="input-group">
          <div class="input-group-text">
            <i class="bi bi-lock-fill"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-key-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                /></svg
            ></i>
          </div>
          <input
            v-on:keyup.enter="login"
            :class="passwordClass"
            autocomplete="current-password"
            type="password"
            v-model="form.password"
            class="form-control"
            placeholder="รหัสผ่าน"
          />
        </div>
      </div>
      <div class="col-sm py-5">
        <button
          type="button"
          @click="login"
          class="w-100 btn btn-primary float-end"
        >
          เข้าสู่ระบบ
        </button>
      </div>
      <div class="col-12">
        <router-link :to="{ name: 'register' }" class="float-end text-primary"
          >ลงทะเบียนเข้าใช้งาน</router-link
        >
      </div>
      <div class="col-12 m-0">
        <router-link
          :to="{ name: 'forgot-password' }"
          class="float-end text-primary"
          >ลืมรหัสผ่าน?</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import { ref, inject } from "vue";
const axios = require("axios");
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const swal = inject("$swal");
    const router = useRouter();
    const store = useStore();

    const form = ref("");
    const employeeIdClass = ref("");
    const passwordClass = ref("");
    const statusCheckEmployeeId = ref(false);
    const statusCheckPassword = ref(false);

    const login = () => {
      if (!form.value.employeeId) {
        employeeIdClass.value = "border border-danger";
        statusCheckEmployeeId.value = false;
      } else {
        statusCheckEmployeeId.value = true;
      }
      if (!form.value.password) {
        passwordClass.value = "border border-danger";
        statusCheckPassword.value = false;
      } else {
        statusCheckPassword.value = true;
      }
      if (statusCheckEmployeeId.value && statusCheckPassword.value) {
        axios
          .post(process.env.VUE_APP_API_URL_BACKEND + "login", form.value)
          .then((res) => {
            if (res.status == 200) {
              localStorage.eqt_jwt = res.data.token;
              localStorage.eqt__uid = res.data._id;
              localStorage.eqt_firstname = res.data.firstname;
              localStorage.eqt_lastname = res.data.lastname;

              var browserName = navigator.userAgent.toLowerCase();

              if (res.data.isSuperAdmin || res.data.isAdmin) {
                localStorage.eqt_adminmenu = 1;
                router.push({
                  name: "dashboard",
                });
              } else {
                router.push({
                  name: "e-quotation",
                });
              }
            } else {
              swal({
                icon: "warning",
                title: "กรุณาติดต่อคุณ ....",
              });
            }
          })
          .catch((err) => {
            swal({
              icon: "error",
              title: err.response.data.message,
            });
          });
      } else {
        return false;
      }
    };

    form.value = {
      employeeId: "",
      password: "",
    };

    return {
      login,
      employeeIdClass,
      passwordClass,
      form,
    };
  },
  created() {
    localStorage.removeItem("eqt__uid");
    localStorage.removeItem("eqt_firstname");
    localStorage.removeItem("eqt_jwt");
    localStorage.removeItem("eqt_lastname");
    localStorage.removeItem("eqt_role_user");
    localStorage.removeItem("eqt_adminmenu");
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
