<template>
  <header>
    <!-- <div class="d-flex flex-column flex-md-row align-items-center pb-2 mb-4 border-bottom">
            <a href="/e-quotation" class="d-flex align-items-center text-dark text-decoration-none">
                <img src="../assets/logo_toa_.png" alt="" width="50">
                <span class="fs-4 px-3"> E - QUOTATION</span>
            </a>
            <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto align-middle">
                <a class="me-3 py-3 text-dark text-decoration-none" href="#">วิธีการใช้งาน</a>
                <a class="me-3 py-3 text-dark text-decoration-none" href="#">Application</a>

                <router-link v-if="employeeData.data.jobLevel == 1" class="me-3 py-3 text-dark text-decoration-none"
                    :to="{ name: 'my-quotation' }">ใบเสนอราคาของฉัน</router-link>
                <router-link v-if="employeeData.data.jobLevel != 1" class="me-3 py-3 text-dark text-decoration-none"
                    :to="{ name: 'manage-quotation' }">จัดการเอกสาร</router-link>
                <router-link v-if="toaomt_adminmenu != null" class="me-3 py-3 text-dark text-decoration-none"
                    :to="{ name: 'dashboard' }">เมนู admin</router-link>
                <a class="me-3 py-3 text-dark text-decoration-none" href="#" @click="logout">ออกจากระบบ</a>
                <img :src="urlProfile" width="60" class="img-thumbnail rounded-circle" alt="...">
            </nav>


        </div> -->

    <nav
      class="navbar navbar-expand-lg bg-light d-flex flex-column flex-md-row align-items-center pb-2 mb-4 border-bottom"
    >
      <div class="container-fluid">
        <a
          href="/e-quotation"
          class="d-flex align-items-center text-dark text-decoration-none"
        >
          <img src="../assets/logo_toa_.png" alt="" width="50" />
          <span class="fs-4 px-3"> E - QUOTATION</span>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link active" href="#">วิธีการใช้งาน</a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle active"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                แอพพลิเคชั่น
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://apps.powerapps.com/play/e/default-40aee372-e641-4a91-acac-8567f11d53e4/a/6cca8029-3583-4cf8-b6f7-4a4a809d4465?tenantId=40aee372-e641-4a91-acac-8567f11d53e4"
                    >Color Formulation</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://apps.powerapps.com/play/e/default-40aee372-e641-4a91-acac-8567f11d53e4/a/61a113ce-2ca1-4173-8d6c-6b83b1710ae1?tenantId=40aee372-e641-4a91-acac-8567f11d53e4"
                    >Sampling Color</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://omt.toagroup.com/"
                    >Delivery Status</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link
                v-if="
                  (employeeData.data.jobLevel == 1 ||
                    employeeData.data.jobLevel == 2) &&
                  eqt_adminmenu.data == null
                "
                class="nav-link active"
                :to="{ name: 'my-quotation' }"
                >ใบเสนอราคาของฉัน</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-if="eqt_adminmenu.data != null"
                class="nav-link active"
                :to="{ name: 'all-equotation' }"
                >ใบเสนอราคาทั้งหมด</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-if="employeeData.data.jobLevel != 1"
                class="nav-link active"
                :to="{ name: 'manage-quotation' }"
                >จัดการเอกสาร</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-if="toaomt_adminmenu != null"
                class="nav-link active"
                :to="{ name: 'dashboard' }"
                >เมนู admin</router-link
              >
            </li>
            <li class="nav-item">
              <a class="nav-link active" href="#" @click="logout">ออกจากระบบ</a>
            </li>
          </ul>
        </div>
        <img
          :src="urlProfile"
          width="60"
          class="img-thumbnail rounded-circle"
          alt="..."
        />
      </div>
    </nav>
  </header>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
const axios = require("axios");
export default {
  setup() {
    const store = useStore();

    const logout = () => {
      store.commit("logout");
    };
    const eqt_adminmenu = reactive({
      data: null,
    });

    const firstname = localStorage.getItem("eqt_firstname");
    const lastname = localStorage.getItem("eqt_lastname");
    const toaomt_adminmenu = localStorage.getItem("eqt_toaomt_adminmenu");

    const urlProfile = `https://ui-avatars.com/api/?name=${firstname}+${lastname}`;

    const employeeData = reactive({
      data: "",
    });

    const getEmployee = async () => {
      await axios
        .get(process.env.VUE_APP_API_URL_BACKEND + "employee_jwt", {
          headers: {
            "x-access-token": localStorage.eqt_jwt,
          },
        })
        .then((res) => {
          employeeData.data = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    };

    onMounted(async () => {
      eqt_adminmenu.data = localStorage.getItem("eqt_adminmenu");
      await getEmployee();
    });

    return {
      logout,
      urlProfile,
      employeeData,
      toaomt_adminmenu,
      eqt_adminmenu,
    };
  },
};
</script>

<style lang="scss" scoped></style>
