import axios from "axios";
export default ({ to, from, next }) => {
  const _uid = localStorage.getItem("eqt__uid");
  axios
    .get(process.env.VUE_APP_API_URL_BACKEND + "users/" + _uid, {
      headers: {
        "x-access-token": localStorage.eqt_jwt,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (!res.data.isSuperAdmin && !res.data.isAdmin) {
          location.replace("login");
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });

  // next()
};
