<template>
    <div class="container">
        <div class="d-flex justify-content-center pt-5">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row text-center pt-5" v-if="render">
            <div class="col">
                <h1>
                    Generateding Processing and Downloading PDF
                    Please wait...
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, onBeforeMount, ref } from 'vue'
import { useRouter, useRoute } from "vue-router";
const axios = require("axios");
import { saveAs } from 'file-saver';
export default {
    setup() {
        const router = useRouter();
        const route = useRoute()
        const quotation_number = ref("")
        const type_doc = ref("")
        const render = ref(false)
        const getQuotation = async () => {
            if (quotation_number.value) {
                await axios.get(process.env.VUE_APP_API_URL_BACKEND + 'quotation_number/' + quotation_number.value, {
                    headers: {
                        'x-access-token': localStorage.eqt_jwt
                    }
                })
                    .then(res => {
                        render.value = res.data
                        console.log('check', res.data);
                        axios.get(process.env.VUE_APP_API_URL_BACKEND + 'pdf_endpoint/' + quotation_number.value + '/' + type_doc.value)
                            .then(res => {
                                console.log('generate', res.data);
                                console.log(process.env.VUE_APP_API_URL_BACKEND + 'pdf/' + res.data.filename);

                                saveAs(process.env.VUE_APP_API_URL_BACKEND + 'pdf/' + res.data.filename, res.data.filename);
                                setTimeout(function () {
                                    // var customWindow = window.open('', '_blank', '');
                                    // customWindow.close();
                                    window.close();
                                }, 5000);
                            })
                            .catch(err => {
                                console.error(err);
                                console.log("someting wrong CHECKQUOTATION-3")
                                alert('someting wrong CHECKQUOTATION-3')
                                // router.push({ name: "home" });
                            })
                    })
                    .catch(err => {

                        console.error(err);
                        console.log("someting wrong CHECKQUOTATION-2")
                        alert('someting wrong CHECKQUOTATION-2')
                        // router.push({ name: "home" });
                    })
            } else {
                console.log("someting wrong CHECKQUOTATION11")
                // alert('Something wrong')
                // router.push({ name: "home" });
            }
        }


        onMounted(async () => {
            await getQuotation()
        })
        onBeforeMount(() => {
            quotation_number.value = route.params.quotation_number
            type_doc.value = route.params.type_doc
            console.log(type_doc.value);

        });
        return {
            render
        }
    }
}
</script>

<style lang="scss" scoped></style>