import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import LoginForm from "../views/Task/LoginForm.vue";
import DashboardView from "../views/DashboardView.vue";
import eQuotationView from "../views/eQuotationView.vue";
import pdfQuotationView from "../views/pdfQuotationView.vue";

import authentication from "../middleware/authentication";
import permissionCheck from "../middleware/permissionCheck.js";
import portal from "../middleware/portal.js";

const routes = [
  {
    path: "/login",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "register",
        name: "register",
        component: () => import("../views/Task/RegisterForm.vue"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("../views/Task/forgotPassword.vue"),
      },
      {
        path: "reset-password/:hashId",
        name: "reset-password",
        component: () => import("../views/Task/resetPassword.vue"),
      },
      {
        path: "",
        name: "login-form",
        component: () => import("../views/Task/LoginForm.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "default",
    meta: {
      middleware: [portal],
    },
    // component: LoginForm,
    // children: [
    // 	{ path: 'register', name: 'register', component: () => import('../views/Task/RegisterForm.vue') },
    // 	{ path: '', name: 'login-form', component: () => import('../views/Task/LoginForm.vue') },
    // ],
  },
  {
    path: "/admin",
    name: "dashboard",
    component: DashboardView,
    meta: {
      middleware: [authentication, permissionCheck],
    },
    redirect: {
      name: "dashboard-default",
    },
    children: [
      {
        path: "",
        name: "dashboard-default",
        component: () => import("../views/Task/DashboardDefault.vue"),
      },
      {
        path: "new-registation-list",
        name: "new-registation-list",
        component: () => import("../views/Task/NewRegistationList.vue"),
      },
      {
        path: "rejected-list",
        name: "rejected-list",
        component: () => import("../views/Task/RejectedList.vue"),
      },
      {
        path: "all-user",
        name: "all-user",
        component: () => import("../views/Task/AllUserList.vue"),
      },
      {
        path: "log-email",
        name: "log-email",
        component: () => import("../views/Task/AllLogEmailList.vue"),
      },
      {
        path: "all-equotation",
        name: "all-equotation",
        component: () => import("../views/Task/AllEquotation.vue"),
      },
      {
        path: "upload-product-form",
        name: "upload-product-form",
        component: () => import("../views/Task/ProductUploadForm.vue"),
      },
    ],
  },
  {
    path: "/e-quotation",
    name: "e-quotation",
    component: eQuotationView,
    meta: {
      middleware: [authentication],
    },
    redirect: {
      name: "equotation-form",
    },
    children: [
      {
        path: "",
        name: "equotation-form",
        component: () => import("../views/Task/eQuotationForm.vue"),
      },
      {
        path: "my-quotation",
        name: "my-quotation",
        component: () => import("../views/Task/eQuotationMy.vue"),
      },
      {
        path: "manage-quotation",
        name: "manage-quotation",
        component: () => import("../views/Task/eQuotationManage.vue"),
      },
      {
        path: "view-quotation/:quotationId",
        name: "view-quotation",
        component: () => import("../views/Task/eQuotationForm.vue"),
      },
      // { path: 'pdf-quotation/:quotationId', name: 'pdf-quotation', component: () => import('../views/Task/eQuotationForm.vue') },
    ],
  },
  {
    path: "/pdf-quotation/:quotation_number/:type_doc",
    name: "pdf-quotation",
    component: pdfQuotationView,
    meta: {
      // middleware: [authentication],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

export default router;
