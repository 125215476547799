<template>
	<div class="login-page bg-light">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="bg-white shadow rounded">
						<div class="row">
							<div class="col-md-6 pe-0" style=" ">
								<router-view></router-view>
							</div>
							<div class="col-md-6 ps-0 d-none d-md-block" style="box-shadow: #bfc3bf9c -8px 6px 15px;">
								<div class="form-right h-100 bg-primary text-white text-center pt-5" :style="cssProps">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			cssProps: {
				backgroundImage: `url(${require('@/assets/bg.jpg')})`,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			},
			cssProps_logo: {
				backgroundImage: `url(${require('@/assets/logo_toa_.png')})`
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.login-page {
	width: 100%;
	height: 100vh;
	display: inline-block;
	display: flex;
	align-items: center;
}

.form-right i {
	font-size: 100px;
}
</style>