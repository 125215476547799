import { createStore } from "vuex";
import axios from "axios";
import router from "../router";
export default createStore({
  state: {
    headManagers: {},
    jobLevel: {},
    userNewRegistation: {},
    user: localStorage.eqt_user,
    company_name: process.env.VUE_APP_COMPANY_NAME,
  },
  mutations: {
    // async getAllHeadManager(state) {
    // 	await fetch(process.env.VUE_APP_API_URL_BACKEND + "users_head_manager")
    // 		.then(res => res.json())
    // 		.then(data => {
    // 			state.headManagers = data;
    // 		})
    // },
    async getJobLevel(state) {
      await fetch(process.env.VUE_APP_API_URL_BACKEND + "users_job_level")
        .then((res) => res.json())
        .then((data) => {
          state.jobLevel = data;
        });
    },
    async checkRoutePermissions(state) {},
    logout(state) {
      localStorage.removeItem("eqt_uid");
      localStorage.removeItem("eqt_firstname");
      localStorage.removeItem("eqt_jwt");
      localStorage.removeItem("eqt_lastname");
      localStorage.removeItem("eqt_role_user");
      localStorage.removeItem("eqt_adminmenu");
      router.push({
        name: "home",
      });
    },
  },
});
