<template>
    <div class="container py-3">
        <HeaderEQuotation></HeaderEQuotation>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
import HeaderEQuotation from '../components/HeaderEQuotation.vue'
export default {
    components: {
        HeaderEQuotation,
    },
}
</script>

<style lang="scss" scoped>
body {
    background-image: linear-gradient(180deg, #eee, #fff 100px, #fff);
}

.container {
    max-width: 960px;
}

.pricing-header {
    max-width: 700px;
}
</style>