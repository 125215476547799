import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import middleware from "vue-router-middleware-system"

router.beforeEach(middleware())

createApp(App)
.use(store)
.use(router)
.use(VueSweetalert2)
.use(middleware)
.mount('#app')
