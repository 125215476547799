import axios from "axios";

export default ({ to, from, next }) => {
  // Your custom if statement
  if (!localStorage.getItem("eqt_jwt")) {
    window.location.href = "/login";
    return false;
  }

  axios
    .get(process.env.VUE_APP_API_URL_BACKEND + "authentication", {
      headers: {
        "x-access-token": localStorage.eqt_jwt,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        window.location.href = "/login";
        return false;
      }
    })
    .catch((err) => {
      window.location.href = "/login";
      console.error(err);
      return false;
    });

  next();
};
