<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3 sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'dashboard' }">
            <span>
              <HomeSVG></HomeSVG>
            </span>
            Dashboard
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
            ><span>
              <PersonLineSVG></PersonLineSVG>
            </span>
            User</a
          >
          <ul class="dropdown-menu">
            <li>
              <router-link class="nav-link" :to="{ name: 'all-user' }">
                <span>
                  <PersonSVG></PersonSVG>
                </span>
                All User</router-link
              >
            </li>
            <li>
              <router-link
                class="nav-link"
                :to="{ name: 'new-registation-list' }"
              >
                <span>
                  <PersonPlusSVG></PersonPlusSVG>
                </span>
                New Registations</router-link
              >
            </li>
            <li>
              <router-link class="nav-link" :to="{ name: 'rejected-list' }">
                <span>
                  <PersonXSVG></PersonXSVG>
                </span>
                Rejected List</router-link
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'log-email' }">
            <span>
              <MailSVG></MailSVG>
            </span>
            Log Email</router-link
          >
        </li>

        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'all-equotation' }">
            <span>
              <MailSVG></MailSVG>
            </span>
            All Quotation</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'equotation-form' }">
            <span>
              <MailSVG></MailSVG>
            </span>
            Create Quotation</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'upload-product-form' }">
            <span>
              <UploadSVG></UploadSVG>
            </span>
            Upload Product</router-link
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="logout"
            ><span>
              <DoorOpenSVG></DoorOpenSVG>
            </span>
            Log out</a
          >
        </li>
        <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                        aria-expanded="false">Dropdown</a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Separated link</a></li>
                    </ul>
                </li> -->
        <!-- <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="shopping-cart" class="align-text-bottom"></span>
                        Products
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="users" class="align-text-bottom"></span>
                        Customers
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="bar-chart-2" class="align-text-bottom"></span>
                        Reports
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="layers" class="align-text-bottom"></span>
                        Integrations
                    </a>
                </li> -->
      </ul>

      <!-- <h6
                class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span>Saved reports</span>
                <a class="link-secondary" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle" class="align-text-bottom"></span>
                </a>
            </h6>
            <ul class="nav flex-column mb-2">
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text" class="align-text-bottom"></span>
                        Current month
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text" class="align-text-bottom"></span>
                        Last quarter
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text" class="align-text-bottom"></span>
                        Social engagement
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text" class="align-text-bottom"></span>
                        Year-end sale
                    </a>
                </li>
            </ul> -->
    </div>
  </nav>
</template>

<script>
import HomeSVG from "./svg/HomeSVG.vue";
import PersonPlusSVG from "./svg/PersonPlusSVG.vue";
import PersonSVG from "./svg/PersonSVG.vue";
import DoorOpenSVG from "./svg/DoorOpenSVG.vue";
import UploadSVG from "./svg/UploadSVG.vue";
import PersonLineSVG from "./svg/PersonLineSVG.vue";
import PersonXSVG from "./svg/PersonXSVG.vue";
import MailSVG from "./svg/MailSVG.vue";
import { useStore } from "vuex";
export default {
  components: {
    MailSVG,
    PersonXSVG,
    PersonLineSVG,
    DoorOpenSVG,
    UploadSVG,
    PersonSVG,
    PersonPlusSVG,
    HomeSVG,
  },
  setup() {
    const store = useStore();

    const logout = () => {
      store.commit("logout");
    };

    return {
      store,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped></style>
